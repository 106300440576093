.body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

@font-face {
  font-family: "Titania";
  src: local("Titania") url("/src/fonts/titania.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat") url("/src/fonts/montserrat.ttf") format("truetype");
}

@font-face {
  font-family: "Titania";
  src: local("Titania"), url("/src/fonts/titania.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("/src/fonts/montserrat.ttf") format("truetype");
}

h1 {
  font-family: "Titania", italic;
  line-height: 1.2 !important;
}

h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.2 !important; /* Smaller than the default */
}

/* Default styles */
.logo-container {
  position: absolute;
  top: 12px;
  left: 24px;
}

.about-button-container {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: end;
}

.button-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-inner-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Styles for devices under 500px */
@media (max-width: 500px) {
  .logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Website */

.website-container {
  width: 100vw;
  min-height: 100dvh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

* {
  box-sizing: border-box;
}

.wrapper {
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr 1fr;
  gap: 1.25em;
  min-height: 100vh;
  width: 100vw;
}

.wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.box1 {
  grid-column: 1 / -1;
  grid-row: 1;
  height: 100px;
}

.box2,
.box3,
.box4,
.box5,
.box6,
.box7 {
  position: relative;
}

.content {
  width: 100%;
  position: absolute;
  top: 0;
}

.box-2-content {
  height: calc(100% + 15%);
}

.box-3-content {
  height: calc(100% + 15%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-5-content,
.box-6-content {
  height: calc(100% - 15%);
  bottom: 0;
  top: auto;
}

.box-4-content {
  height: calc(100% + 66%);
}

.box-7-content {
  height: calc(100% - 66%);
  bottom: 0;
  top: auto;
}

@media (max-width: 1000px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 1fr;
  }
}

/* Mobile Styles */
@media (max-width: 500px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 1fr;
  }

  .content {
    position: static;
    height: 100px;
  }
  .box-3-content {
    height: 324px;
  }
}
